:root {
  --color-bg-light: #f6f2ed;
  --color-secondary: #ecc94b;
  --dsg-cell-disabled-background-color: rgba(0, 0, 0, 0) !important;
  --dsg-cell-background-color: rgba(0, 0, 0, 0) !important;
  /* ... */
}

/* 
 * Google Map styles
 */
.yNHHyP-marker-view svg {
  fill: #ffa357;
}

/* HTML marker styles */
.price-tag {
  zindex: 3100;
}

input:focus {
  outline: none;
}

.dsg-input {
  font-size: 12px !important;
}

.dsg-row:nth-child(odd) {
  background-color: #f9f9f9;
}

.dsg-row:nth-child(even) {
  background-color: #ffffff;
}

.dsg-cell-header-container {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #222;
}

.dsg-cell-gutter {
  background-color: #f9f9f9 !important;
}

.scrollbar-top {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
  height: calc(100% + 17px); /* Adjust height to fit the scrollbar */
}

.scrollbar-top::-webkit-scrollbar {
  height: 8px;
}

.scrollbar-top::-webkit-scrollbar-thumb {
  background-color: #888; /* Style the scrollbar thumb */
}

.scrollbar-top::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Style the scrollbar track */
}

.scrollbar-top {
  scrollbar-gutter: stable; /* Prevent layout shift due to scrollbar */
}

/* Move the scrollbar to the top */
.scrollbar-top::-webkit-scrollbar {
  -webkit-overflow-scrolling: touch;
}
.scrollbar-top::-webkit-scrollbar:horizontal {
  top: 0;
}

.yNHHyP-marker-view svg text {
  fill: #ffffff;
  font-size: 62px;
}

.text-stroke {
  color: white;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  left: -60px;
  width: 120px !important;
  font-size: 10px;
  font-weight: 400;
}

.bg-rating {
  background: rgb(179, 224, 195);
  background: linear-gradient(
    90deg,
    rgba(179, 224, 195, 1) 0%,
    rgba(249, 230, 195, 1) 40%,
    rgba(249, 219, 189, 1) 65%,
    rgba(250, 197, 177, 1) 100%
  );
}

.bg-rating-vlow {
  background: rgb(179, 224, 195);
}

.bg-rating-low {
  background: rgb(179, 224, 195);
  background: linear-gradient(90deg, rgba(179, 224, 195, 1) 3%, rgba(249, 230, 195, 1) 100%);
}
.bg-rating-medium {
  background: rgb(249, 230, 195);
  background: linear-gradient(90deg, rgba(249, 230, 195, 1) 3%, rgba(249, 230, 195, 1) 100%);
}
.bg-rating-high {
  background: rgb(249, 219, 189);
  background: linear-gradient(90deg, rgba(249, 219, 189, 1) 54%, rgba(250, 197, 177, 1) 100%);
}
.bg-rating-vhigh {
  background: rgb(250, 197, 177);
  background: linear-gradient(90deg, rgba(250, 197, 177, 1) 0%, rgba(250, 197, 177, 1) 100%);
}

#map * {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: all 0s ease;
  transition: none;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../src/assets/fonts/avenir/AvenirNextLTPro-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../src/assets/fonts/avenir/AvenirNextLTPro-Bold.otf');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../src/assets/fonts/avenir/AvenirNextLTPro-It.otf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next';
  src: url('../src/assets/fonts/avenir/AvenirNextLTPro-Medium.otf');
  font-weight: 600;
  font-style: medium;
}
@font-face {
  font-family: 'Avenir Next';
  src: url('../src/assets/fonts/avenir/Avenir Next LT Pro Demi.otf');
  font-weight: 700;
  font-style: demibold;
}

.MuiCircularProgress-circle {
  stroke: white !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.mapboxgl-ctrl-group {
  opacity: 0;
}

a {
}

h1 {
  font-weight: 700;
}

@tailwind utilities;

/*
 * Google Map styles
 */
.HJDHPx-interactive svg {
  fill: #ffa357;
}

.price-tag {
  zindex: 3100;
}

.HJDHPx-interactive svg text {
  opacity: 0;
}

.text-stroke {
  color: white;
  text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  left: -60px;
  width: 120px !important;
  font-size: 10px;
  font-weight: 400;
}

#map * {
  -moz-transition: none;
  -webkit-transition: none;
  -o-transition: all 0s ease;
  transition: none;
}

/* HTML marker styles

.price-tag::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 85%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #fb923c;
}

*/

table,
th,
td {
  padding: 10px 5px;
}

.select-dropdown {
  .fl__clear-indicator {
    display: none;
  }
  .fl__indicator-separator {
    display: none;
  }
  .fl__multi-value {
    background-color: rgba(41, 145, 132, 0.1);
    border-radius: 6px;
  }
  .fl__multi-value__label {
    padding: 0px 3px;
    font-size: 10px;
  }
  .fl__value-container {
    padding: 0px;
  }
  .fl__value-container {
    max-height: 50px;
  }
  .fl__menu {
    font-size: 12px;
    padding: 0px;
    z-index: 50;
  }
  .fl__input-container {
    background: white;
  }
  .fl__placeholder {
    background: white;
    color: #444;
  }
  .fl__control,
  .fl__control:hover {
    border: none;
    background: white;
  }
  .fl__control--is-focused {
    border: none !important;
  }
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #8cc99f;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
}

.mapboxgl-popup-content {
  padding: 0px !important;
  opacity: 0.98 !important;
  border-radius: 6px !important;
}

.brf-popup .mapboxgl-popup-tip {
  border-bottom-color: #e4e7e9 !important;
}

.riskMap .mapboxgl-popup-tip {
  border-bottom-color: #e2e4e7 !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  @apply dark:border-b-dark-morefaded #{!important};
  @apply opacity-[0.98] #{!important};
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  @apply dark:border-b-dark-morefaded #{!important};
  @apply opacity-[0.98] #{!important};
  border-left-color: #299184 !important;
  display: none;
}

.selected-popup {
  z-index: 50;
}

button:focus {
  outline: none;
}
div:focus {
  outline: none;
}

.example-container {
  width: 320px;
  padding: 20px;
}

.MuiLinearProgress-root {
  background-color: #eff5f5 !important;
  @apply dark:bg-dark-primary #{!important};
}

.MuiLinearProgress-bar {
  background-color: rgba(41, 145, 132, 1) !important;
  @apply dark:bg-dark-prop #{!important};
}

.fl__control {
  border-width: 0px !important;
  border-radius: 0px !important;
  border-bottom-width: 1px !important;
  box-shadow: none !important;
}

.fl__control--is-focused,
.fl__control--is-focused:hover {
  border-width: 0px !important;
  border-radius: 0px !important;
  border-bottom-width: 1px !important;
  border-bottom-color: #ccc !important;
}

.pretty-line {
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #ddd;
  line-height: 0.01em;
  margin: 10px 0 20px;
}
.pretty-line span {
  background: #fff;
  padding: 0 10px;
}

.dropzone_area {
  padding-top: 5px;
  padding-left: 5px;
  height: 100%;
  width: 100%;
  margin-left: -5px;
  margin-top: -5px;
  min-height: 60vh;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  border: 2px dashed transparent;
  flex-direction: column;
  transition: background-color 0.3s ease;
  -webkit-transition: background-color 0.3s ease;
  > div {
    width: 100%;
  }
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75px;
  }
}

.dropzone_area.hovering {
  background-color: #e1eee2;
  border: 2px dashed red;
}

.refresh {
  padding: 10px;
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.content-placeholder {
  padding: 20px;
  transform-origin: top center;
}

.widget-width {
  width: 32%;
  min-width: 280px;
}

// header {
//   background: #0055ff;
//   border-radius: 10px;
//   color: white;
//   cursor: pointer;
//   height: 40px;
//   margin-bottom: 20px;
// }

.svg-icon.disabled {
  stroke: #bbb;
}

.svg-icon.disabled:hover {
  stroke: #bbb;
}

.word {
  height: 18px;
  border-radius: 10px;
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 8px;
  background: #0055ff;
  border-radius: 10px;
  display: inline-block;
}

.paragraph {
  margin-bottom: 20px;
}

section {
  overflow: hidden;
}

.scrollable::-webkit-scrollbar-thumb {
  background-color: #0a9396;
}

.scrollable::-webkit-scrollbar-track {
  background-color: #888;
}

.scrollable::-webkit-scrollbar-corner {
  background-color: #888;
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
}

.scrollable {
  -ms-overflow-style: auto;
  scrollbar-color: #0a9396 #888;
  scrollbar-width: thin;
}

.custom-gradient-mask-right {
  mask-image: linear-gradient(to right, rgba(0, 0, 0, 1) 95%, transparent 100%);
}

.custom-overflow-y-initial {
  overflow-y: initial !important;
}

@media (max-width: 1024px) {
  h1 {
    font-size: 24px !important;
    line-height: 24px !important;
  }

  h2 {
    font-size: 30px !important;
    line-height: 24px !important;
  }
}

@media (max-width: 600px) {
  .content-placeholder {
    padding-left: 20px;
  }

  .header .word {
    height: 30px;
  }

  .word {
    height: 14px;
    margin-bottom: 5px;
    margin-right: 5px;
  }

  .paragraph {
    margin-bottom: 20px;
  }
}

.react-pdf-custom-highlighted-text {
  background: #ff7060;
  opacity: 0.4;

  &.current {
    opacity: 0.8;
  }
}

@layer components {
  .search-react-select-container .search-react-select__control {
    @apply bg-transparent border-0 hover:border-neutral-400 dark:hover:border-neutral-500;
  }

  .search-react-select-container .search-react-select__control--is-focused {
    @apply border-neutral-500 hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400 shadow-none;
  }

  .search-react-select-container .search-react-select__menu {
    @apply bg-neutral-100 dark:bg-dark-lessfaded border-0 border-neutral-300 dark:border-neutral-600;
  }

  .search-react-select-container .search-react-select__option {
    @apply text-neutral-600 dark:text-neutral-200 bg-neutral-100 hover:bg-neutral-200 dark:bg-dark-lessfaded dark:hover:bg-neutral-800;
  }

  .search-react-select-container .search-react-select__indicator-separator {
    @apply bg-neutral-400;
  }

  .search-react-select-container .search-react-select__input-container,
  .search-react-select-container .search-react-select__placeholder,
  .search-react-select-container .search-react-select__multi-value {
    @apply text-gray-500 dark:text-gray-400;
  }

  .search-react-select-container .search-react-select__multi-value,
  .search-react-select-container .search-react-select__multi-value__label,
  .search-react-select-container .search-react-select__multi-value__remove {
    @apply bg-gray-200 dark:bg-dark-morefaded text-neutral-600 dark:text-gray-300;
  }

  .search-react-select-container .search-react-select__multi-value__remove:hover {
    @apply bg-gray-200 dark:bg-dark-morefaded text-neutral-600 dark:text-gray-300;
  }

  .search-react-select-container .search-react-select__indicator {
    @apply hidden;
  }

  .search-react-select-container .search-react-select__indicator-separator {
    @apply hidden;
  }

  /* xlsx table */
  .data-sheet {
    table {
      thead {
        tr {
          @apply border dark:bg-dark-primary dark:border-gray-500 border-card-graypanel text-center whitespace-nowrap;
          th {
            @apply border-r dark:border-gray-500 border-card-graypanel px-3 py-3 font-semibold;
          }
        }
      }
      tbody {
        tr {
          @apply border dark:bg-dark-primary dark:border-gray-500 border-card-graypanel text-center whitespace-nowrap;
          &:nth-child(odd) {
            @apply bg-[rgba(148,_210,_189,_0.07)] dark:bg-[rgba(148,_210,_189,_0.1)];
          }
          &:nth-child(even) {
            @apply dark:bg-dark-primary;
          }
          td {
            @apply border-r dark:border-gray-500 border-card-graypanel px-3 py-3;
          }
        }
      }
    }
  }
}

.react-tel-input {
  .country-list .country {
    @apply bg-white dark:bg-dark-faded #{!important};
    &:hover {
      @apply bg-white dark:bg-dark-lessfaded #{!important};
    }
  }
  .country-list {
    @apply w-[348px] xl:w-[378px] 2xl:w-[438px] #{!important};
  }
  .flag-dropdown {
    @apply bg-white dark:bg-dark-lessfaded dark:border-gray-500 #{!important};
    &:hover {
      @apply dark:bg-dark-morefaded;
    }
    &.open .selected-flag {
      @apply dark:bg-dark-morefaded dark:border-gray-500;
    }
    .selected-flag {
      &:hover,
      &:focus {
        @apply dark:bg-dark-faded;
      }
    }
  }
}

.gm-style .gm-style-iw-c {
  @apply dark:bg-dark-lessfaded #{!important};
}
.gm-style .gm-style-iw-d {
  overflow: auto !important;
}
.gm-style .gm-ui-hover-effect {
  span {
    @apply dark:bg-white #{!important};
  }
}
.gm-style .gm-style-iw-tc::after {
  @apply dark:bg-dark-lessfaded #{!important};
}
.gm-style .gm-control-active {
  @apply dark:bg-dark-lessfaded #{!important};
}
.dark .gm-style .gm-control-active:hover {
  img:nth-child(1) {
    display: inline;
    filter: brightness(200%);
  }
  img:nth-child(2),
  img:nth-child(3) {
    display: none;
  }
}
.gm-style .gm-fullscreen-control {
  @apply dark:bg-dark-lessfaded #{!important};
}
.gm-style .gmnoprint > div > div:nth-child(2) {
  @apply dark:bg-dark-morefaded m-0 w-full #{!important};
}
.dark .gm-style .gmnoprint > div > button:nth-child(1):hover {
  img:nth-child(4) {
    display: inline;
  }
}
